import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";

const configuracionLocal = (props) => {
  return (
    <div className={props.popup == true ? "popup active" : "popup "}>
      <div className="contenido">
        <figure className="successIcon">
          <Icon.Settings size={36} />
        </figure>
        <h2>Configuración local</h2>

        <button
          className="clear"
          id="close"
          onClick={() => props.setPopup(false)}
        >
          <Icon.X size={24} />
        </button>

        <form>
          <div className="grupoinput">
            <label>Resultados por consulta </label>
            <input type="number" required />
          </div>

          <div className="grupoinput">
            <label>Mostar imagen de producto en fila </label>
            <input type="number" required />
          </div>

          <div className="grupoBotones">
            <button
              type="reset"
              className="cancelar"
              onClick={() => props.setPopup(false)}
            >
              Cancelar
            </button>
            <button className="primario">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default configuracionLocal;
