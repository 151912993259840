import React, { useState } from "react";

const Rombo = () => {
  return (
    <svg
      height="50"
      viewBox="0 0 41 63"
      xmlns="http://www.w3.org/2000/svg"
      className="rombo"
    >
      <path d="M7.32824 1.13995C6.43257 0.3257 5.37405 0 4.39695 0C4.11527 0 3.83358 0.0389778 3.5519 0.0899654C3.30097 0.135388 3.05588 0.209288 2.8222 0.311403C2.70363 0.363217 2.58829 0.415031 2.47093 0.466845C2.18554 0.592849 1.91527 0.750053 1.67272 0.946262C1.58798 1.01482 1.50211 1.08999 1.41976 1.16141C1.1556 1.39049 0.944215 1.67235 0.732824 1.9542C0.244275 2.6056 0 3.41985 0 4.39695V26.276C0 29.1393 1.22742 31.8653 3.3713 33.7633L35.5013 62.2087C37.6721 64.1241 40.3053 61.6531 40.3053 58.7581V34.7974C40.3053 31.9313 39.0756 29.2031 36.9283 27.3049L7.32824 1.13995Z" />
    </svg>
  );
};

export default Rombo;
