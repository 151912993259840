import React, {
  createContext,
  Suspense,
  lazy,
  useEffect,
  useState,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenuLateral from "./componentes/menuLateral";
import Cookies from "js-cookie";
import SimpleLoader from "./componentes/loader/simpleLoader";
import axios from "axios";

// Importa los componentes usando lazy
const Login = lazy(() => import("./modulos/login/login"));
const Inicio = lazy(() => import("./modulos/inicio/inicio"));
const Inventario = lazy(() => import("./modulos/inventario/inventario"));
const InventarioProductos = lazy(() =>
  import("./modulos/inventario/productos/tabla")
);
const IngresoDeMercancia = lazy(() =>
  import("./modulos/inventario/ingreso-de-mercancia/ingreso-de-mercancia")
);
const IngresoDeMercanciaSkuUnico = lazy(() =>
  import("./modulos/inventario/ingreso-de-mercancia/sku-unico")
);
const IngresoDeMercanciaSkuDiferente = lazy(() =>
  import("./modulos/inventario/ingreso-de-mercancia/sku-diferente")
);
const PosicionamientoDeCajas = lazy(() =>
  import("./modulos/inventario/posicionamiento-de-cajas")
);
const ConsolidacionDeCajas = lazy(() =>
  import("./modulos/inventario/consolidacion-de-cajas")
);
const InventarioDanado = lazy(() =>
  import("./modulos/inventario/inventario-danado")
);
const Auditorias = lazy(() => import("./modulos/inventario/auditorias"));
const Insumos = lazy(() => import("./modulos/inventario/insumos"));
const Pedidos = lazy(() => import("./modulos/pedidos/pedidos"));
const Despacho = lazy(() => import("./modulos/pedidos/despacho"));
const Facturacion = lazy(() => import("./modulos/pedidos/facturacion"));
const Clientes = lazy(() => import("./modulos/clientes/clientes"));
const Usuarios = lazy(() => import("./modulos/usuarios/tabla"));
const UsuariosAction = lazy(() => import("./modulos/usuarios/action"));
const Error404 = lazy(() => import("./componentes/error404"));

export const authContext = createContext();

const API_AUTH = process.env.REACT_APP_API_AUTH;
const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [auth, setAuth] = useState();

  const validarToken = async () => {
    try {
      const response = await axios.post(API_AUTH + "/validar-token", { token });
      if (response.data.valid) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    } catch (error) {
      console.error("Error validating token:", error);
      return { valid: false, error: "Error validating token" };
    }
  };

  useEffect(() => {
    validarToken();
  }, []);

  if (auth) {
    return (
      <authContext.Provider value={{ token }}>
        <Router>
          <MenuLateral />
          <Suspense fallback={<SimpleLoader></SimpleLoader>}>
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/login" element={<Inicio />} />
              <Route path="/inventario" element={<Inventario />} />
              <Route
                path="/inventario/productos"
                element={<InventarioProductos />}
              />
              <Route
                path="/inventario/ingreso-de-mercancia"
                element={<IngresoDeMercancia />}
              />
              <Route
                path="/inventario/ingreso-de-mercancia/sku-unico"
                element={<IngresoDeMercanciaSkuUnico />}
              />
              <Route
                path="/inventario/ingreso-de-mercancia/sku-diferente"
                element={<IngresoDeMercanciaSkuDiferente />}
              />
              <Route
                path="/inventario/posicionamiento-de-cajas"
                element={<PosicionamientoDeCajas />}
              />
              <Route
                path="/inventario/consolidacion-de-cajas"
                element={<ConsolidacionDeCajas />}
              />
              <Route
                path="/inventario/inventario-danado"
                element={<InventarioDanado />}
              />
              <Route path="/inventario/auditorias" element={<Auditorias />} />
              <Route path="/inventario/insumos" element={<Insumos />} />
              <Route path="/pedidos" element={<Pedidos />} />
              <Route path="/pedidos/despacho" element={<Despacho />} />
              <Route path="/pedidos/facturacion" element={<Facturacion />} />
              <Route path="/clientes" element={<Clientes />} />
              <Route path="/usuarios" element={<Usuarios />} />
              <Route path="/usuarios/agregar" element={<UsuariosAction />} />
              <Route path="/usuarios/editar" element={<UsuariosAction />} />

              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </Router>
      </authContext.Provider>
    );
  } else {
    return (
      <Router>
        <Suspense fallback={<SimpleLoader></SimpleLoader>}>
          <Routes>
            <Route path="*" element={<Login {...{ setAuth }} />} />
          </Routes>
        </Suspense>
      </Router>
    );
  }
};

export default App;
