import React, { useState, useEffect, Fragment } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import isotipo from "../img/isotipo.svg";
import ConfiguracionLocal from "./configuracionLocal";

const MenuLateral = () => {
  const [popup, setPopup] = useState(false);

  const [moduloActual, setModuloActual] = useState(obtenerPrimerDirectorio());
  const [subModuloActual, setSubModuloActual] = useState(
    window.location.pathname
  );
  const [fechaActual, setFechaActual] = useState(obtenerFechaActual());
  const [horaActual, setHoraActual] = useState("");

  // Función para obtener la fecha actual en formato día/mes/año
  function obtenerFechaActual() {
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses comienzan desde 0, por lo que sumamos 1
    const año = fecha.getFullYear();
    return `${dia < 10 ? "0" + dia : dia}/${mes < 10 ? "0" + mes : mes}/${año}`;
  }

  function obtenerPrimerDirectorio() {
    const urlActual = window.location.pathname; // Obtiene la ruta actual sin el nombre del dominio
    const segmentos = urlActual.split("/"); // Divide la ruta en segmentos usando '/'
    if (segmentos.length > 1) {
      return "/" + segmentos[1]; // Retorna el primer segmento, si existe
    } else {
      return null; // Retorna null si la ruta no tiene ningún segmento después del dominio
    }
  }

  useEffect(() => {
    // Función para obtener la hora actual
    const obtenerHoraActual = () => {
      const fecha = new Date();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      // Formatea la hora como "hh:mm:ss"
      const horaFormateada = `${hora < 10 ? "0" + hora : hora}:${
        minutos < 10 ? "0" + minutos : minutos
      }`;
      return horaFormateada;
    };

    // Actualiza la hora actual
    const actualizarHora = () => {
      setHoraActual(obtenerHoraActual());
    };

    // Llama a la función para obtener la hora actual
    actualizarHora();

    // Configura un temporizador para actualizar la hora cada minuto
    const intervalo = setInterval(() => {
      actualizarHora();
    }, 60000); // 60000 milisegundos = 1 minuto

    // Limpia el temporizador al desmontar el componente
    return () => clearInterval(intervalo);
  }, []);

  return (
    <Fragment>
      <div id="menu-lateral">
        <header>
          <Link
            to="/"
            onClick={() => setModuloActual("inicio")}
            id="isotipo-link"
            className="clear"
          >
            <img src={isotipo} />
          </Link>

          <div id="user">
            <div id="data">
              <img src="" />
              <span>
                <p id="nombre">Username</p>
                <p id="rol">Puesto</p>
              </span>
            </div>
            <button className="icon-button">
              <Icon.LogOut size={18} />
            </button>
          </div>
        </header>
        <div id="tools">
          {/* <div>
          <Icon.Home size={16} />
          ZAMORA
          <Icon.ChevronDown size={16} />
        </div> */}

          <div>
            <button className=" icon-button" onClick={() => setPopup(true)}>
              <Icon.Settings size={16} />
            </button>
          </div>

          <div>
            <Icon.Calendar size={16} />
            {fechaActual}
          </div>

          <div>
            <Icon.Clock size={16} />
            {horaActual}
          </div>
        </div>

        <nav>
          <Link
            to="/"
            onClick={(event) => setModuloActual("/inicio")}
            className={moduloActual == "/inicio" ? "active" : " "}
          >
            Inicio
            <Icon.Home size={18} />
          </Link>

          <Link to="/inventario" onClick={() => setModuloActual("/inventario")}>
            Inventario
            <Icon.ChevronDown
              size={18}
              style={
                moduloActual == "/inventario"
                  ? { transform: "rotate(180deg)" }
                  : { transform: "rotate(0deg)" }
              }
            />
          </Link>
          <ul
            className="submenu"
            style={
              moduloActual == "/inventario"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Link
              to="/inventario/productos"
              onClick={() => setSubModuloActual("/inventario/productos")}
              className={
                subModuloActual == "/inventario/productos" ? "active" : " "
              }
            >
              <Icon.Box size={18} />
              Productos
            </Link>
            <Link
              to="/inventario/ingreso-de-mercancia"
              onClick={() =>
                setSubModuloActual("/inventario/ingreso-de-mercancia")
              }
              className={
                subModuloActual == "/inventario/ingreso-de-mercancia"
                  ? "active"
                  : " "
              }
            >
              <Icon.ArrowRightCircle size={18} />
              Ingreso de mercancía
            </Link>
            <Link
              to="/inventario/posicionamiento-de-cajas"
              onClick={() =>
                setSubModuloActual("/inventario/posicionamiento-de-cajas")
              }
              className={
                subModuloActual == "/inventario/posicionamiento-de-cajas"
                  ? "active"
                  : " "
              }
            >
              <Icon.Codesandbox size={18} />
              Posicionamiento de cajas
            </Link>
            <Link
              to="/inventario/consolidacion-de-cajas"
              onClick={() =>
                setSubModuloActual("/inventario/consolidacion-de-cajas")
              }
              className={
                subModuloActual == "/inventario/consolidacion-de-cajas"
                  ? "active"
                  : " "
              }
            >
              <Icon.Minimize2 size={18} />
              Consolidación de cajas
            </Link>
            <Link
              to="/inventario/inventario-danado"
              onClick={() =>
                setSubModuloActual("/inventario/inventario-danado")
              }
              className={
                subModuloActual == "/inventario/inventario-danado"
                  ? "active"
                  : " "
              }
            >
              <Icon.AlertTriangle size={18} />
              Inventario dañado
            </Link>
            <Link
              to="/inventario/auditorias"
              onClick={() => setSubModuloActual("/inventario/auditorias")}
              className={
                subModuloActual == "/inventario/auditorias" ? "active" : " "
              }
            >
              <Icon.FileText size={18} />
              Auditorias
            </Link>
            <Link
              to="/inventario/insumos"
              onClick={() => setSubModuloActual("/inventario/insumos")}
              className={
                subModuloActual == "/inventario/insumos" ? "active" : " "
              }
            >
              <Icon.ShoppingBag size={18} />
              Insumos
            </Link>
          </ul>

          <Link to="pedidos" onClick={() => setModuloActual("/pedidos")}>
            Pedidos
            <Icon.ChevronDown
              size={18}
              style={
                moduloActual == "/pedidos"
                  ? { transform: "rotate(180deg)" }
                  : { transform: "rotate(0deg)" }
              }
            />
          </Link>
          <ul
            className="submenu"
            style={
              moduloActual == "/pedidos"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Link
              to="/pedidos/despacho"
              onClick={() => setSubModuloActual("/pedidos/despacho")}
              className={
                subModuloActual == "/pedidos/despacho" ? "active" : " "
              }
            >
              <Icon.Package size={18} />
              Despacho
            </Link>
            <Link
              to="/pedidos/facturacion"
              onClick={() => setSubModuloActual("/pedidos/facturacion")}
              className={
                subModuloActual == "/pedidos/facturacion" ? "active" : " "
              }
            >
              <Icon.FileText size={18} />
              Facturación
            </Link>
          </ul>
          <Link
            to="clientes"
            onClick={() => setModuloActual("/clientes")}
            className={moduloActual == "/clientes" ? "active" : " "}
          >
            Clientes
            <Icon.ChevronDown size={18} style={{ opacity: 0 }} />
          </Link>
          <Link
            to="usuarios"
            onClick={() => setModuloActual("/usuarios")}
            className={moduloActual == "/usuarios" ? "active" : " "}
          >
            Usuarios
            <Icon.ChevronDown size={18} style={{ opacity: 0 }} />
          </Link>
        </nav>
      </div>
      <ConfiguracionLocal {...{ popup, setPopup }}></ConfiguracionLocal>
    </Fragment>
  );
};

export default MenuLateral;
