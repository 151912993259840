import React, { useState } from "react";
import Rombo from "./rombo";

const SimpleLoader = () => {
  return (
    <main className="loader">
      <Rombo></Rombo>
    </main>
  );
};

export default SimpleLoader;
